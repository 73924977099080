@media screen and (min-width: 600px) {
  .page_container {
    width:60%;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {

}


* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background-color: #222831;
}


.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}
.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.winning_space {
  color: green;
}

.tab_background {
  border-radius: 5px;
  background-color: #0B090C;
  padding: 25px;
  margin-top: 15px;
}

.tab_container {
  display: flex;
  justify-content: center;
}

.about_me {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 50px;
  width: 900px;
}

.about_me a:link { color: #aaf !important; }
.about_me a:visited { color: #caf !important; }

.about_me p {
  margin-left: 10px;
}

.about_me_item {
  padding: 10px;
  background-color: #3C4142;
  border-radius: 15px;
}

.about_me_large_photo {
  display: flex;
  justify-content: center;
}

.about_me_large_photo img {
  border-radius: 5px;
  width: 100%;
  /* max-height: 500px */
}

.about_me_small_photos {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 5px;
  background-color: #0B090C;
  border-radius: 5px;
}

.about_me_small_photos img {
  border-radius: 8px;
  height: 200px;
  padding: 3px;
  max-width: 270px;
}


.about_me :is(h1, h2) {
  text-align: center;
}

.nav_tabs {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 25px;
  /* border-top: 2px SOLID #3C4142; */
}

.nav_tabs button {
  border-radius: 10px;
  background-color: lightblue;
  width: 150px;
  height: 45px;

}

.upper_contact_info {
  height: 60px;
  color: white;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px SOLID black;
}

.bottom_contact_info {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.my_pic {
  border-radius: 200px;
  height: 200px;
  margin-left: 15%;
  margin-top: 20px;
  width: 200px;
}

.contact_icons {
  display: flex;
  justify-content: space-between;
}

.contact_icons i {
  color: #222831;  
  font-size: 22px;
  padding: 5px;
}

.contact_icons a {
  background-color: lightgray;
  height: 35px;
  width: 35px;
  border-radius: 90px;
  text-align: center;
  color: #fff;
  margin: 8px;
}

.swe_description_brief {
  width: 58%;
  padding-top: 40px;
}

.swe_descrip_and_skills {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.swe_descrip_and_skills figure {
  margin: 0;
  width: 40%;
}

.swe_descrip_and_skills h3 {
  text-align: center;
  text-decoration: underline;
}

.swe_skill_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}


.swe_description {
  display: flex;
  flex-direction: column;
}


.under_construction {
  text-align: center;
  color: white;
}

.creation_text {
  margin-right: 30px;
}

.name_text {
  margin-left: 4%;
}

.gallery_container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
}

.nav_btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.gallery_container img {
  height: 500px;
  margin-left: 10%;
  margin-right: 10%;
}
/* https://coolors.co/bdfffd-9ffff5-7cffc4-6abea7-5e6973 */